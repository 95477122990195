<template>
  <div title="List Intro Insider">
    <b-container fluid="sm" class="mb-5">
      <b-row class="text-right">
        <b-col>
          <!-- <b-button @click="createCom" variant="success">
            <plus-icon size="1.2x" class="custom-class"></plus-icon>ADD
          </b-button> -->
        </b-col>
      </b-row>
    </b-container>

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }" :line-numbers="true" :pagination-options="{
  enabled: true,
  perPage: pageLength
}">
      <template slot="table-row" slot-scope="props">

        <!-- Column: Name -->
        <span v-if="props.column.field === 'image' && props.row.image" class="text-nowrap">
          <b-img :src="imgUrl(props.row.image)" class="mx-1" v-bind="mainProps"></b-img>
        </span>

        <span v-else-if="props.column.field === 'background_image' && props.row.background_image" class="text-nowrap">
          <b-img :src="backgroundImgUrl(props.row.background_image)" class="mx-1" v-bind="mainProps"></b-img>
        </span>

        <!-- Column: Status -->
        <!-- <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span> -->

        <!-- Column: Router -->
        <span v-else-if="props.column.field === 'name'" class="text-nowrap">
          <router-link :to="{ name: 'company-view', params: { id: props.row.id } }">{{ props.row.name }}</router-link>
        </span>

        <!-- Column: strip_connect -->
 <span v-else-if="props.column.field === 'strip_connect'">
  <b-button @click="generateLink(props.row.id)" variant="success" class="d-flex align-items-center">
    <feather-icon icon="LinkIcon" class="mr-50" />
    <span>Generate Link</span>
  </b-button>
</span>

<span v-else-if="props.column.field === 'stripe_account_id'">
  <b-button variant="warning" class="text-white" @click="checkData(props.row.id)">
    <feather-icon icon="SendIcon" class="mr-50" />
    <span>check the account status</span>
  </b-button>
</span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
  <b-button variant="success" class="text-white" @click="sendData(props.row.id)">
    <feather-icon icon="SendIcon" class="mr-50" />
    <span>Send Stripe Connect link</span>
  </b-button>
</span>




        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select v-model="pageLength" :options="['5', '10', '25']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import axios from "axios";
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BContainer, BRow, BCol, BImg
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import { PlusIcon } from 'vue-feather-icons'
//Database
import { db } from "@/main";
// import axios from 'axios';
// Vue.use(VueAxios,axios)

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BContainer,
    BRow,
    BCol,
    BImg,
    PlusIcon,
  },
  data() {
    return {
      
      loading: false, 
         companyId: 'your_company_id_here', 
      mainProps: { blank: false, width: 75, height: 75, class: 'm1' },
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
        },   
        {
    label:'Strip Account Details',
    field:'stripe_account_id',
        },   
        {
   label:'stripe_status',
   field:'stripe_status',
        }, 
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  methods: {
    async checkData(id) {
  try {
    store.dispatch('app/commitActivateLoader');
    // const response = await axios.post('http://127.0.0.1:8000/api/checkaccount', { id });
    const response =  await axios.post('https://intro-api.cwsdev1.com/api/checkaccount', { id });

    if (response.data.status === true) {
      store.dispatch('app/commitDeactivateLoader');

      const { charges_enabled, payouts_enabled, details_submitted } = response.data;

      if (!charges_enabled) {
        alert("❌ Payments are disabled on this account.");
      } else if (!payouts_enabled) {
        alert("⚠️ Payouts are disabled. Please check your Stripe settings.");
      } else if (!details_submitted) {
        alert("⚠️ Stripe account details are not fully submitted.");
      } else {
        alert("✅ Stripe account is fully active and ready.");
      }

      this.$bvToast.toast("Account checked successfully!", {
        title: "Success",
        variant: "success",
        solid: true,
      });

    } else {
      store.dispatch('app/commitDeactivateLoader');
      alert(`❌ ${response.data.message}`);
      this.$bvToast.toast(response.data.message, {
        title: "Error",
        variant: "danger",
        solid: true,
      });
    }

    console.log(response.data);
  } catch (error) {
    store.dispatch('app/commitDeactivateLoader');
    console.error('Error checking account:', error);
    alert("⚠️ Stripe account ID not found.");
    this.$bvToast.toast("An error occurred while checking the account.", {
      title: "Error",
      variant: "danger",
      solid: true,
    });
  }
},


    async sendData(id) {
  try {
    store.dispatch('app/commitActivateLoader');
    // const response =  await axios.post('http://127.0.0.1:8000/api/send-data', { id });
    const response =  await axios.post('https://intro-api.cwsdev1.com/api/send-data', { id });

    if (response.data.status === true) {
      store.dispatch('app/commitDeactivateLoader');
      this.$bvToast.toast("Link sent successfully!", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    } else {
      store.dispatch('app/commitDeactivateLoader');
      this.$bvToast.toast("Failed to send the link.", {
        title: "Error",
        variant: "danger",
        solid: true,
      });
    }

    console.log(response.data);
  } catch (error) {
    store.dispatch('app/commitDeactivateLoader');
    console.error('Error sending data:', error);
    this.$bvToast.toast("An error occurred while sending data.", {
      title: "Error",
      variant: "danger",
      solid: true,
    });
  }
},




    createCom() {
      this.$router.push({ name: "company-create" })
    },

    readEmployees() {
  store.dispatch('app/commitActivateLoader');

  this.rows = [];
  var i = 1;
  db.collection("companies")
    .orderBy("modified")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let companyData = doc.data();
        db.collection("countries").doc(companyData.country).get()
          .then(() => {
            let row = {
              index: i,
              id: doc.id,
              name: companyData.name,
              stripe_account_id: companyData.stripe_account_id,
              stripe_status: '' // Placeholder text
            };

        
       axios.post('https://intro-api.cwsdev1.com/api/checkaccount', { id: doc.id  })

  .then((response) => {
    if (response.data.status) {
      row.stripe_status = response.data.charges_enabled && response.data.payouts_enabled
        ? 'Connected'
        : 'Not Connected';
        
    } else {
      row.stripe_status = 'Not Connected';
    }
  })
  .catch((error) => {
    console.error("Stripe check error:", error);
    row.stripe_status = 'No Conneted ID found';
  });


            this.rows.push(row);
            i++;
          });

        store.dispatch('app/commitDeactivateLoader');
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      store.dispatch('app/commitDeactivateLoader');
    });
},

  



  },
  mounted() {
    this.readEmployees();
  },
};
</script>
